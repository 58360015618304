<script setup>
import {useStore} from "vuex";
import {useRoute, useRouter} from "vue-router";
import {useI18n} from "vue-i18n";
import {computed, ref, watch} from "vue";
import configs from "@/configs";
import axios from "axios";
import {parseTimeString} from "@/common/utils";
import ScheduleArticle from "@/components/ScheduleArticle.vue";

const store = useStore();
const router = useRouter();
const route = useRoute();
const { t } = useI18n();
const token = computed(() => store.getters.token);
console.log(`token: ${JSON.stringify(token.value)}`);
const isAdmin = computed(() => store.getters.isAdmin);

const props = defineProps(['id'])
console.log(`character id for schedule: ${props.id}`);

const showDialog = ref(false);
const apiCalling = ref(false);
let schedules = ref([]);

let composeTime = function(hour) {
  const now = new Date();

  // Set the hour and reset minutes, seconds, and milliseconds to zero
  now.setUTCHours(hour, 0, 0, 0);

  // Convert to local timezone
  const localTime = now.toLocaleTimeString(
      [], { hour: '2-digit', minute: '2-digit', hour12: true });

  const [timePart, amPmPart] = localTime.split(' ');

  return {
    time: timePart, // HH:MM part
    period: amPmPart // AM/PM part
  };
}

let article_types = [
    "K", "B", "M", "A"
]

let hasType = function(schedule, type) {
  let types = schedule.args.article_types;
  if (!types || types.length === 0) {
    return false;
  }

  for (let check_type of types) {
    if (check_type.startsWith(type.toLowerCase())) {
      return true;
    }
  }

  return false;
}

let cancelSchedule = async function(schedule) {
  const sid = schedule.id;
  console.log(`cancel schedule: ${sid}`);
  if (!sid) {
    console.error(`sid cannot be empty.`)
    return;
  }

  console.log(`token: ${token.value}`)
  if (!isAdmin) {
    console.error(`no permit to run this in non-admin page.`)
    return;
  }

  let urlPrefix = configs.cloudFunctionPrefix;
  console.log(`urlPrefix: ${urlPrefix}`);

  let url = urlPrefix + `/v1/schedule/delete?id=${sid}`;
  console.log(`url: ${url}`);

  try {
    schedule.apiCalling = true;
    let ret = await axios.post(url, {},{
      headers: {
        Authorization: `Bearer ${token.value}`,
      },
    });

    if (ret && ret.data && ret.data.code === 200) {
      await getSchedules();
    }
  } catch (e) {
    console.error(`failed to call cancel api: ${e}`);
  } finally {
    schedule.apiCalling = false;
  }
}

let getSchedules = async function () {
  console.log(`get schedules for character: ${props.id}`);
  const characterId = props.id
  console.log(`characterId: ${characterId}`)
  if (!characterId) {
    console.error(`character ID is not set yet.`)
    return;
  }

  console.log(`token: ${token.value}`)
  if (!isAdmin) {
    console.error(`no permit to run this in non-admin page.`)
    return;
  }

  let urlPrefix = configs.cloudFunctionPrefix;
  console.log(`urlPrefix: ${urlPrefix}`);

  let url = urlPrefix + `/v1/schedule/find`;
  console.log(`url: ${url}`);

  url += `?cid=${characterId}&type=write_article`;

  try {
    apiCalling.value = true;
    let ret = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${token.value}`,
      },
    });

    if (ret && ret.data && ret.data.code === 200) {
      let listOfSchedules = ret.data.schedules;

      if (listOfSchedules) {
        schedules.value = listOfSchedules.sort((a, b) => a.time_slot - b.time_slot);
      }

      console.log(`[${characterId}]'s schedules: ${JSON.stringify(schedules.value )}`);
    }
  } catch (e) {
    console.error(`failed to call schedule article api: ${e}`);
  } finally {
    apiCalling.value = false;
  }
}

getSchedules();
</script>

<template>
  <v-dialog
      v-model="showDialog"
      max-width="340">
    <template v-slot:default="{ isActive }">
      <v-card
          :title='$t("message.dialogSchedulesTitle")'
      >
        <template v-slot:prepend>
          <img
              width="32"
              style="margin: 0 10px"
              src="@/assets/392529_alarm_alert_clock_event_history_icon.svg" />
        </template>
        <template v-slot:actions>
          <v-spacer></v-spacer>
          <v-btn
              class="ml-auto"
              :text='$t("message.commonActionBack")'
              @click="showDialog = false"
          ></v-btn>
          <v-spacer></v-spacer>

        </template>
        <ScheduleArticle :id="props.id" @done="getSchedules()"></ScheduleArticle>
        <v-list>
          <v-list-item
              class="schedule"
              v-for="(schedule, i) in schedules" :key="i"
          >
            <v-row
                align="center"
                justify="center"
            >
              <v-col
                  class="schedule-item"
                  cols="6"
                  md="6"
              >
                <span class="schedule-time">{{ composeTime(schedule.time_slot).time }} </span>
                <span>{{ composeTime(schedule.time_slot).period }}</span>
              </v-col>
              <v-spacer></v-spacer>
              <v-row
                  align="center"
                  justify="center"
                  style="margin: 0 -10px 0 0"
              >
                <v-btn
                    v-if="!schedule.apiCalling"
                    variant="plain"
                    v-on:click="cancelSchedule(schedule)"
                >
                  <v-img width="32" src="@/assets/4115238_delete_trash_icon.svg" />
                </v-btn>
                <v-progress-circular
                    v-else
                    :size="25"
                    :width="3"
                    indeterminate
                    style="margin-top: -4px"
                ></v-progress-circular>

              </v-row>
            </v-row>
            <v-row
                class="article-types"
                align="center"
                justify="center">
              <v-col
                  cols="2"
                  md="2"
                  class="article-type"
                  v-for="(type, i) in article_types"
              >
                <v-chip
                    :variant="hasType(schedule, type) ? 'flat' : 'tonal'"
                >
                  {{type}}
                </v-chip>
              </v-col>
              <v-spacer/>
            </v-row>
          </v-list-item>
        </v-list>
      </v-card>
    </template>
  </v-dialog>
  <div v-show="isAdmin">
    <v-btn
        v-if="!apiCalling"
        variant="plain"
        class="gen-article-btn"
        @click="showDialog = true"
    >
      <img src="@/assets/392529_alarm_alert_clock_event_history_icon.svg" />
    </v-btn>
    <v-progress-circular
        v-else
        :size="25"
        :width="3"
        indeterminate
        style="margin-top: -4px"
    ></v-progress-circular>
  </div>
</template>

<style scoped>
.schedule .v-row {
  margin: 0;
}

.schedule-time {
  font-size: 36px;
  font-weight: bold;
  padding-right: 4px;
}

.schedule-item {
  padding: 8px 8px;
}

.article-types {
  margin: 16px;
  padding: 0 8px;
}

.article-type {
  padding: 0;

}
.article-type .v-chip {

}

</style>
